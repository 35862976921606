$busy: #2176ea;
$free: #ff28ff;
$soon: #b418ae;

.driver-marker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .marker {
    color: white;
    display: flex;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    border-width: 4px;
    font-size: 10px;
    font-weight: 700;
    border-style: solid;
    justify-content: center;
    align-items: center;
    background: rgb(148, 144, 144);
    &.free {
      background: $free;
    }
    &.busy {
      background: $busy;
    }
    &.soon {
      background: $soon;
    }
    &.dimmed {
      opacity: 0.25;
    }
  }
}
